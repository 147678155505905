require('./bootstrap');
require('datatables.net-bs4');

import '@babel/polyfill';

import $ from 'jquery';
window.$ = window.jQuery = $;

import Swal from 'sweetalert2';
window.Swal = Swal
 
